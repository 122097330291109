import styled from "styled-components";

const StyledLink = styled.a`
color: #06BDD6;
 a:hover {
     text-decoration: none!important;
     color: #fff;
 }
`;

export default StyledLink

